import React, {useEffect, useState} from 'react';
import {FetchDataNew} from "../../Utils/FetchData";
import Loader from "../Loader";
import GetBooks from "../Books/GetBooks";
import {useParams, withRouter} from "react-router-dom";
import {TitleComponent} from "../../Utils/TitleComponent";

function Challenge() {
    const { year } = useParams();
    const [load, setLoad] = useState(true); // auf false setzen, sobald der Rest auch geladen wird.
    const [books, setBooks] = useState(null);

    /*
    das hier aktivieren, um weitere Daten über das Jahr und die Challenge zu holen!
    useEffect(() => {
        getBooksOfYear();
    }, [year]);

    const getBooksOfYear = async () => {
        const data = await FetchDataNew("books/year/" + year);
        setBooks(data);
        setLoad(true);
    };
     */

    if (load) {
        return (
            <React.Fragment>
                <TitleComponent title={`Mein Jahr ${year} in Büchern`} />
                <h1 className="componentTitle">Mein Jahr {year} in Büchern</h1>
                <p>
                    Hier folgen dann noch so Dinge wie Anzahl Seiten gelesen, Anzahl Bücher gelesen.
                    Kürzestes Buch. Längstes Buch. Durchschnittliche Buch-länge.
                    Average-Rating. Highest Rated. Lowest Rated.
                    Progress-Bar für die Reading-Challenge (wie viel Bücher gelesen in % von denen, die es gibt)

                </p>
                <GetBooks subtitle={`Meine ${year} Bücher`} apiPath={"books/year/" + year} />
            </React.Fragment>
        );
    } else {
        return (
            <div>
                <Loader/>
            </div>
        );
    }
}

export default withRouter(Challenge);
