/**
 * Author model
 */
class Author {
    constructor(data = {}) {
        // Author according to internal database schema

        this.id = null;
        this.name = null;
        this.author_slug = null;
        this.image_url = null;
        this.birthday = null;
        this.description = null;

        Object.assign(this, data);
    }
}
export default Author;
