import React, {useEffect, useState} from 'react';
import BooksList from "./BooksList";
import {FetchDataNew} from "../../Utils/FetchData";
import {useParams, withRouter} from 'react-router-dom'
import Loader from "../Loader";

function GetBooks({apiPath, title, subtitle}) {
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        gettingBooks();
    }, [apiPath]);

    const gettingBooks = async () => {
        const data = await FetchDataNew(apiPath );
        setData(data);
        setLoad(true);
    };


    if (error || (load && !data)) {
        return (<div>Fehler beim Laden. {error}</div>);
    } else if (load) {
        return (
            <React.Fragment>
                <h1 className="componentTitle">{title}</h1>
                <h2 className="componentSubTitle">{subtitle}</h2>
                <BooksList books={Object.values(data)} />
            </React.Fragment>
        )
    } else {
        return (<Loader/>)
    }
}

export default withRouter(GetBooks);