import React, {useEffect, useState} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import BooksList from "./BooksList";
import {FetchDataNew} from "../../Utils/FetchData";
import Loader from "../Loader";

function BooksHome() {
    const [books, setBooks] = useState([]);
    const [error, setError] = useState('');
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const fetchMoreData = async () => {
        const data = await FetchDataNew("books/pages/" + page + "/finished");
        if (!data || data.length === 0) {
            setHasMore(false);
        } else {
            setBooks(books.concat(Object.values(data)));
            setPage(page + 1);
        }
    };

    useEffect(() => {
        fetchMoreData();
    }, []);

    if (error) {
        return (<div>{error.message}</div>)
    } else {
        return (
            <InfiniteScroll
                dataLength={page}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<Loader/>}
                className="infinite"
                endMessage={<p>Yay! Du bist am Ende angekommen.</p>}
                style={ {overflow: "hidden"}}
            >
                <h1 className="componentTitle">Gelesen</h1>
                <BooksList books={books}/>
            </InfiniteScroll>
        )
    }
}

export default BooksHome;