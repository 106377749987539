import BookItem from "./BookItem";
import React from "react";

function BooksList( {books} ) {
    return (
        <div className="bookslist">
            {books && books.map(book =>
                <BookItem key={book.ID} book={book}/>
            )}
        </div>
    )
}

export default BooksList;