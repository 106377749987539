import axios from "axios";

const development = false;

const axiosNew = axios.create({
    baseURL: development ? 'https://api.new-books.exodia.ch/' : 'https://api.new-books.exodia.ch/'
});

export const FetchDataNew = async (uri) => {
    try {

        const response = await axiosNew.get(uri);

        return response.data; // everything went well
    } catch (e) {
        errorHandling(e);
    }
}

const errorHandling = (e) => {
    if (e.response) {
        console.log("Request made and server responded");
        return (
            e.message +
            ". " +
            JSON.stringify(e.response.data) +
            ". " +
            JSON.stringify(e.response.status) +
            ". " +
            JSON.stringify(e.response.headers)
        );
    } else if (e.request) {
        console.log("The request was made but no response was received");

        return JSON.stringify(e.request);
    } else {
        console.log(
            "Something happened in setting up the request that triggered an Error"
        );
        return e.message;
    }
}