import React from 'react';
import {Link} from "react-router-dom";
import Genre from "../../Models/Genre";

const GenreList = ( {genres} ) => {
    return (
        <h3>{genres && genres.map((genre, index) => <GenreLink genre={genre} index={index} />)}</h3>
    );
}

const GenreLink = ( {genre, index} ) => {
    genre = new Genre(genre);
    if (index >= 1) {
        return (
            <React.Fragment>
                ,
                <Link key={genre.id} to={"/genre/" + genre.slug} >
                    {genre.name}
                </Link>
            </React.Fragment>
        );
    } else {
        return (
            <Link key={genre.id} to={"/genre/" + genre.slug} >
                {genre.name}
            </Link>
        );
    }

}

export default GenreList