import React from 'react';
import {Link} from "react-router-dom";
import {Img} from 'react-image'
import Loader from "../Loader";
import Book from "../../Models/Book";
import AuthorList from "../Author/AuthorList";

const BookItem = ( {book} ) => {
    book = new Book(book);
    //console.log(book)

    return (
        <div key={book.id} className="bookitem">

            <Link key={book.id} to={"/book/" + book.slug} >
                <div className="image">
                    <Img
                        alt={book.title}
                        src={book.image_url_small}
                        className="cover"
                        loader={Loader()}
                    />
                </div>
                <h2 className="booktitle">{book.title}</h2>
                <AuthorList authors={book.authors} />
            </Link>

        </div>
    );
}

export default BookItem