import React, {useEffect, useState} from 'react';
import {FetchDataNew} from "../../Utils/FetchData";
import Loader from "../Loader";
import {Img} from 'react-image';
import GetBooks from "../Books/GetBooks";
import {useParams, withRouter} from "react-router-dom";
import Author from "../../Models/Author";
import {TitleComponent} from "../../Utils/TitleComponent";


function AuthorDetail() {
    const { authorSlug } = useParams();
    const [load, setLoad] = useState(false);
    const [author, setAuthor] = useState(new Author(null));

    useEffect(() => {
        getAuthor();
    }, [authorSlug]);

    const getAuthor = async () => {
        const data = await FetchDataNew("authors/" + authorSlug);
        setAuthor(data);
        setLoad(true);
    };

    if (load) {

        return (
            <React.Fragment>
                <TitleComponent title={author.name} />
                <h1 className="componentTitle">{author.name}</h1>
                <p>
                    Hier folgen dann noch die anderen Dinge zum Author wie Geburtstag: {author.birthday},
                    Beschreibung: {author.description}, oder ein Bild <Img src={author.image_url} />
                </p>
                <GetBooks apiPath={"authors/" + authorSlug + "/books"} subtitle={"Bücher von " + author.name} />
            </React.Fragment>
        );
    } else {
        return (
            <div>
                <Loader/>
            </div>
        );
    }
}

export default withRouter(AuthorDetail);
