import React, {useEffect, useState} from 'react';
import {FetchDataNew} from "../../Utils/FetchData";
import Loader from "../Loader";
import GetBooks from "../Books/GetBooks";
import {useParams, withRouter} from "react-router-dom";
import Genre from "../../Models/Genre";
import {TitleComponent} from "../../Utils/TitleComponent";


function GenreDetail() {
    const { genreSlug } = useParams();
    const [load, setLoad] = useState(false);
    const [genre, setGenre] = useState(new Genre(null));

    useEffect(() => {
        getGenre();
    }, [genreSlug]);

    const getGenre = async () => {
        const data = await FetchDataNew("genres/" + genreSlug);
        setGenre(data);
        setLoad(true);
    };

    const GenreName =  genre.name_de ? genre.name_de : genre.name;

    if (load) {
        return (
            <React.Fragment>
                <TitleComponent title={GenreName} />
                <h1 className="componentTitle">{GenreName}</h1>
                <p>
                    {genre.description}
                </p>
                <GetBooks apiPath={"genres/" + genreSlug + "/books"} subtitle={"Bücher zum Thema " + GenreName} />
            </React.Fragment>
        );
    } else {
        return (
            <div>
                <Loader/>
            </div>
        );
    }
}

export default withRouter(GenreDetail);
