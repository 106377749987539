import React, {useEffect, useState} from 'react';
import {TitleComponent} from "../../Utils/TitleComponent";
import {useParams} from "react-router-dom";
import {FetchDataNew} from "../../Utils/FetchData";
import Loader from "../Loader";
import {Img} from 'react-image';
import Book from "../../Models/Book";
import AuthorList from "../Author/AuthorList";
import GenreList from "../Genre/GenreList";


function BookDetail() {
    const [book, setBook] = useState(new Book());
    const { slug } = useParams();
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        gettingBook();
    }, []);

    const gettingBook = async () => {
        const data = await FetchDataNew("books/" + slug);
        setBook(data);
        setLoad(true);
    };

    if (error) {
        return (<div>
            {error}
        </div>
        );
    } else if (load) {
        return (
            <React.Fragment>
                <TitleComponent title={book.title} />
                <div className="bookdetail">
                    <div className="detail-image">
                        <Img alt={book.title} src={book.image_url} className="cover" loader={Loader()}/>
                    </div>
                    <div className="detail-info">
                        <h1 className="componentTitle">{book.title}</h1>
                        {/*<p className="belowComponentTitle">By <Link to={"/author/" + author_id + "/" + slugify(author_name)}>
                                {author_name}</Link>
                        </p>*/}
                        <AuthorList authors={book.authors} />
                        <p className="metadata">
                            {book.publisher && (<>Verlag: {book.publisher}<br /></>)}
                            {book.published && (<>Veröffentlicht am: {book.published}<br /></>)}
                            {book.pages && (<>{book.pages} Seiten <br /></>)}
                            {book.started_reading && (<>Angefangen zu lesen am {book.started_reading}<br /></>)}
                            {book.finished_reading && (<>Fertig gelesen am {book.finished_reading}<br /></>)}
                        </p>
                        <GenreList genres={book.genres} />
                        <p className="wysiwyg" dangerouslySetInnerHTML={{__html: book.description}} />
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <div>
                <Loader/>
            </div>
        );
    }
}

export default BookDetail;
