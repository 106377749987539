import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import BooksHome from "./Books/BooksHome";
import NoMatch from "./NoMatch";
import BookDetail from "./Books/BookDetail";
import {TitleComponent} from "../Utils/TitleComponent";
import GetBooks from "./Books/GetBooks";
import SideBar from "./SideBar";
import Profile from "./Profile";
import AuthorDetail from "./Author/AuthorDetail";
import Challenge from "./Challenge/Challenge";
import GenreDetail from "./Genre/GenreDetail";

function App() {
    return (
        <Router>
            <div className="app">
                <Route component={SideBar} />
                <div className="wrapper">
                    <div className="content">
                          <Switch>
                              <Route exact path="/reading-challenge/:year">
                                  <Challenge />
                              </Route>
                              <Route exact path="/genre/:genreSlug">
                                  <GenreDetail />
                              </Route>
                              <Route exact path="/author/:authorSlug">
                                  <AuthorDetail />
                              </Route>
                              <Route exact path="/book/:slug" component={BookDetail} />
                              <Route exact path="/">
                                  <TitleComponent />
                                  <div className="cntbottom">
                                    <Profile/>
                                  </div>
                                  <div className="cntbottom">
                                      <GetBooks title="Am lesen" apiPath="/books/reading" />
                                  </div>
                                  <BooksHome/>
                              </Route>
                              <Route path="*">
                                  <NoMatch />
                              </Route>
                          </Switch>
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;