/**
 * Genre model
 */
class Genre {
    constructor(data = {}) {
        // Genre according to internal database schema

        this.id = null;
        this.name = null;
        this.name_de = null;
        this.slug = null;
        this.description = null;

        Object.assign(this, data);
    }
}
export default Genre;
