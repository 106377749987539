import React from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';
import MenuBookIcon from '@material-ui/icons/MenuBook';

function Profile() {
    return (
        <>
            <div className="profile">
                <img src="/images/florian_avatar.jpg" alt="Florian" />
                <div className="profile-content">
                    <p>
                        Hoi, ich heisse Florian, oder auch einfach Flo. 
                        Ich habe Wirtschaftsinformatik an der Universität Zürich studiert und setze mein Wissen nun als IT-Berater ein.  
                        Wenn ich nicht grad eine Runde laufen gehe oder aus einem kniffligen Escape-Room ausbreche, verbringe ich sehr gerne Zeit mit Büchern.
                        Auf dieser Website kombiniere ich meine Leidenschaft für die Technik mit meiner Passion für Bücher zu meiner persönlichen kleinen Bibliothek.
                        Schau dich doch etwas um!<br />
                    </p>
                    <a rel="noopener noreferrer" title="Flo auf Goodreads" className="icon" href="https://twitter.com/d4rkytv" target="_blank"><TwitterIcon /></a>
                    <a rel="noopener noreferrer" title="Flo auf Twitter" className="icon" href="https://www.goodreads.com/florian_burkhardt" target="_blank"><MenuBookIcon /></a>
                </div>
            </div>
        </>
    );
}

export default Profile;