import React from 'react';
import {Link} from "react-router-dom";
import Author from "../../Models/Author";

const AuthorList = ( {authors} ) => {
    //console.log(authors);
    return (
        <h3 className="authorname">Von {authors && authors.map((author, index) => <AuthorLink author={author} index={index} />)}</h3>
    );
}

const AuthorLink = ( {author, index} ) => {
    author = new Author(author);
    if (index >= 1) {
        return (
            <React.Fragment>
                ,
                <Link key={author.id} to={"/author/" + author.author_slug} >
                    {author.name}
                </Link>
            </React.Fragment>
            );
    } else {
        return (
            <Link key={author.id} to={"/author/" + author.author_slug} >
                {author.name}
            </Link>
        );
    }

}

export default AuthorList